import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import RandomLoader from "../../components/loader/randomLoader.jsx";
import { addKitchen } from "../../services/apis/touAgencyApi";
import { states } from "../../services/constants";

const AddKitchen = () => {
  const color = useColorModeValue("bg.300", "dark.400");
  const colorz = useColorModeValue("bg.120", "white");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { mutate: kitchenMutate, isLoading: KitchenLoading } = useMutation(
    addKitchen,
    {
      onError: (error) => {
        if (Array.isArray(error.response.data.error)) {
          error.response.data.error.map((item) => toast.error(item));
        } else {
          toast.error(error.response.data.msg || "some thing went wrong");
        }
      },
      onSuccess: (res) => {
        toast.success("Added successfully");
        reset();
      },
    }
  );

  const onSubmit = (value) => {
    kitchenMutate(value);
  };

  return (
    <>
      {KitchenLoading ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <RandomLoader />
          <Text fontSize={["1xl", "3xl", "4xl", "6xl"]} position={"absolute"}>
            Loading
          </Text>
        </div>
      ) : null}
      <Box mt="6" mb="20" position={"relative"}>
        <Flex justify="space-between" p={[4, 4, 4, 4]}>
          <Text
            fontSize={["1xl", "2xl", "2xl", "3xl"]}
            as="h1"
            fontWeight="bold"
          >
            Add Kitchen
          </Text>
        </Flex>
        <Box maxW={700} mx="auto" borderRadius="lg" mt="4" bg={color}>
          <Flex p={[4, 4, 4, 4]} justify="space-between">
            <Text
              color={colorz}
              fontSize={["1xl", "2xl"]}
              as="h1"
              fontWeight="bold"
            ></Text>
          </Flex>
          <Box p={4}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl>
                <FormLabel>First Name</FormLabel>
                <Input
                  id="firstName"
                  placeholder="firstName"
                  {...register("firstName", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 2",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.firstName && errors.firstName.message}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Last Name</FormLabel>
                <Input
                  id="lastName"
                  placeholder="lastName"
                  {...register("lastName", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 2",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.lastName && errors.lastName.message}
                </FormHelperText>
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Home Type</FormLabel>
                <Select
                  {...register("homeType", {
                    required: "This is required",
                  })}
                  placeholder="Select Home Type"
                >
                  <option value="Single Family">Singel Family </option>
                  <option value="Town House">Town House</option>
                  <option value="Apartment">Apartment</option>
                  <option value="Condo">Condo</option>
                  <option value="Other">Other</option>
                </Select>
                <FormHelperText color="red">
                  {errors.homeType && errors.homeType.message}
                </FormHelperText>
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Home Owner</FormLabel>
                <Select
                  {...register("homeOwener", {
                    required: "This is required",
                  })}
                  placeholder="Select Owner"
                >
                  <option value={"Yes"}>Yes</option>
                  <option value={"No"}>No</option>
                </Select>
                <FormHelperText color="red">
                  {errors.homeOwener && errors.homeOwener.message}
                </FormHelperText>
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Do you have walls</FormLabel>
                <Select
                  {...register("walls", {
                    required: "This is required",
                  })}
                  placeholder="Select walls">
                  <option value={"Yes"}>Yes</option>
                  <option value={"No"}>No</option>
                </Select>
                <FormHelperText color="red">
                  {errors.walls&& errors.walls.message}
                </FormHelperText>
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Project Type</FormLabel>
                <Select
                  {...register("projectType", {
                    required: "This is required",
                  })}
                  placeholder="Select Number"
                >
                  {[
                    "Complete Kitchen Remodel",
                    "Cabinet Refacing",
                    "Cabinet Installation",
                  ].map((item, key) => (
                    <option value={item} key={key}>
                      {item}
                    </option>
                  ))}
                </Select>
                <FormHelperText color="red">
                  {errors.projectType && errors.projectType.message}
                </FormHelperText>
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Time Frame</FormLabel>
                <Select
                  {...register("timeFrame", {
                    required: "This is required",
                  })}
                  placeholder="Select Number"
                >
                  <option value={"Immediately"}>Immediately</option>
                  <option value={"Within 1 Month"}>With in One Month</option>
                  <option value={"1-3 Months"}>1 to 3 Months</option>
                  <option value={"3 Months and more"}>3 Months or More</option>
                </Select>
                <FormHelperText color="red">
                  {errors.timeFrame && errors.timeFrame.message}
                </FormHelperText>
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Call Back Time</FormLabel>
                <Select
                  {...register("callBtime", {
                    required: "This is required",
                  })}
                  placeholder="Select Number"
                >
                  <option value={"Morning"}>Morning</option>
                  <option value={"Afternoon"}>Afternoon</option>
                  <option value={"Evening"}>Evening</option>
                  <option value={"Anytime"}>Any Time</option>
                </Select>
                <FormHelperText color="red">
                  {errors.callBtime && errors.callBtime.message}
                </FormHelperText>
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>State</FormLabel>
                <Select
                  {...register("state", {
                    required: "This is required",
                  })}
                  placeholder="Select state"
                >
                  {states.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Select>
                <FormHelperText color="red">
                  {errors.state && errors.state.message}
                </FormHelperText>
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>City</FormLabel>
                <Input
                  id="City"
                  placeholder="City"
                  {...register("city", {
                    required: "This is required",
                    minLength: {
                      value: 2,
                      message: "Minimum length should be 2",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.city && errors.city.message}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Zip Code</FormLabel>
                <Input
                  id="zip"
                  placeholder="zip"
                  {...register("zip", {
                    required: "This is required",
                    minLength: {
                      value: 5,
                      message: "Minimum length should be 5",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.zip && errors.zip.message}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Phone Number</FormLabel>
                <Input
                  id="phoneNumber"
                  placeholder="phoneNumber"
                  {...register("phoneNumber", {
                    required: "This is required",
                    minLength: {
                      value: 4,
                      message: "Minimum length should be 4",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.phoneNumber && errors.phoneNumber.message}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                  id="email"
                  placeholder="email"
                  {...register("email", {
                    required: "required",
                    pattern: {
                      value:
                        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      message: "invalid email address",
                    },
                  })}
                />
                <FormHelperText color="red">
                  {errors.email && errors.email.message}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Address</FormLabel>
                <Input
                  id="address"
                  placeholder="address"
                  {...register("address", {
                    required: "required",
                  })}
                />
                <FormHelperText color="red">
                  {errors.address && errors.address.message}
                </FormHelperText>
              </FormControl>
              <Flex justify="end">
                <Button
                  type="submit"
                  mt={4}
                  colorScheme="green"
                  variant="solid"
                >
                  Submit
                </Button>
              </Flex>
            </form>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AddKitchen;

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  SimpleGrid,
  Spinner,
  VStack,
} from "@chakra-ui/react";
import { Text, useColorModeValue } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { getDnc } from "../services/apis/touAgencyApi";
import { MdMoodBad, MdOutlineCleaningServices } from "react-icons/md";
import { IoMdHappy } from "react-icons/io";

//d7d7d770
const Dnc = () => {
  const color = useColorModeValue("bg.300", "dark.400");
  const colorz = useColorModeValue("bg.400", "dark.120");

  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const {
    mutate,
    isLoading: zipLoading,
    data,
  } = useMutation(getDnc, {
    onError: (error) => {
      toast.error(error.response.data.msg);
    },
    onSuccess: () => {
      toast.success("Data Fetched");
      queryClient.invalidateQueries("projects");
      reset();
    },
  });

  const onSubmit = async (value) => {
    console.log(value);
    mutate(value.number);
  };

  return (
    <>
      <Box mt="6">
        <Flex justify="space-between" p={[4, 4, 4, 4]}>
          <Text
            fontSize={["1xl", "2xl", "2xl", "3xl"]}
            as="h1"
            fontWeight="bold"
          >
            Search DNC
          </Text>
        </Flex>
        <SimpleGrid gap={4} columns={[1, 2]}>
          <Box borderRadius="lg" mt="4" bg={color}>
            <Flex p={4} justify="space-between">
              <Text
                color={colorz}
                fontSize={["1xl", "2xl"]}
                as="h1"
                fontWeight="bold"
              ></Text>
            </Flex>
            <Box p={4}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl>
                  <FormLabel>Phone Number</FormLabel>
                  <Input
                    id="zipcode"
                    placeholder="number"
                    {...register("number", {
                      required: "This is required",
                      minLength: {
                        value: 4,
                        message: "Minimum length should be 4",
                      },
                    })}
                  />
                  <FormHelperText color="red">
                    {errors.number && errors.number.message}
                  </FormHelperText>
                </FormControl>
                <Flex justify="end">
                  <Button
                    disabled={zipLoading}
                    type="submit"
                    mt={4}
                    colorScheme="green"
                    variant="solid"
                  >
                    {zipLoading ? <Spinner /> : "Submit"}
                  </Button>
                </Flex>
              </form>
            </Box>
          </Box>
          <Box borderRadius="lg" mt="4" bg={color}>
            <Box p={4}>
              <VStack align="start" gap={4}>
                <Text fontSize="2xl" fontWeight="bold" as={"h2"}>
                  Phone Number: {data?.phone_number}
                </Text>
                <Flex align="center" gap={8}>
                  <Text fontSize="2xl" as={"p"}>
                    isClean
                  </Text>
                  {data?.clean ? (
                    <Text fontSize="2xl" as={"p"}>
                      <MdOutlineCleaningServices color="green.200" />
                    </Text>
                  ) : (
                    <Text fontSize="2xl" as={"p"}>
                      <MdOutlineCleaningServices color="red" />
                    </Text>
                  )}
                </Flex>{" "}
                <Flex align="center" gap={8}>
                  <Text fontSize="2xl" as={"p"}>
                    Bad number
                  </Text>
                  {!data?.is_bad_number? (
                    <Text fontSize="2xl" as={"p"}>
                      <IoMdHappy color="green.200" />
                    </Text>
                  ) : (
                    <Text fontSize="2xl" as={"p"}>
                      <MdMoodBad color="red" />
                    </Text>
                  )}
                </Flex>{" "}
              </VStack>
            </Box>
          </Box>
        </SimpleGrid>
      </Box>
    </>
  );
};

export default Dnc;

import {
  Box,
  Center,
  Flex,
  Heading,
  IconButton,
  Image,
  Input,
  Select,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import { Text, useColorModeValue } from "@chakra-ui/react";
import { useQuery } from "react-query";
import {
  getLogsProfitxl,
  getRenovieLead,
} from "../../services/apis/touAgencyApi";
import ErrorImage from "../../asserts/error.png";
import {
  AiOutlineCheckCircle,
  AiOutlineCloseCircle,
  AiOutlineSearch,
} from "react-icons/ai";
import { RiSignalTowerFill } from "react-icons/ri";
import { HiOutlineStatusOffline } from "react-icons/hi";
import { BiSupport } from "react-icons/bi";
import Pagination from "../../components/Pagination/Pagination";
import { useState } from "react";
import PaginationLoader from "../../components/Pagination/PaginationLoader";
import { useSearchParams } from "react-router-dom";
//d7d7d770
const RenovizeLeads = () => {
  const color = useColorModeValue("bg.300", "dark.400");
  const colorz = useColorModeValue("bg.400", "dark.120");
  let [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(
    searchParams.get("l") ? parseInt(searchParams.get("p")) : 1
  );
  const [limit, setLimit] = useState(
    searchParams.get("l") ? parseInt(searchParams.get("l")) : 10
  );
  const [search, setSearch] = useState(
    searchParams.get("input") ? searchParams.get("input") : ""
  );
  const { data, isLoading, isError } = useQuery({
    queryKey: ["renovize", page, limit, search],
    queryFn: () => getRenovieLead(page, limit, search),
    keepPreviousData: true,
  });
  console.log("--------->", data);
  if (isLoading) {
    return (
      <Flex align="center" h="400px" justify="center">
        <Spinner />
      </Flex>
    );
  }
  if (isError) {
    return (
      <Flex mt="6" align="center" h="400px" justify="center">
        <Box>
          <Image src={ErrorImage} w="400px" />
          <Heading textAlign="center" mt={4} fontSize="2xl" color="red">
            {" "}
            Some error occoured
          </Heading>
        </Box>
      </Flex>
    );
  }
  const handelForm = (e) => {
    e.preventDefault();
    setSearch(e.target.input.value);
    setSearchParams({ input: e.target.input.value, p: page, l: limit });
  };
  return (
    <Box mt="6">
      <Flex justify="space-between" p={[4, 4, 4, 4]}>
        <Text fontSize={["1xl", "2xl", "2xl", "3xl"]} as="h1" fontWeight="bold">
          Renovize Leads
        </Text>
      </Flex>
      <Flex justify={"end"} p={4}>
        <form onSubmit={handelForm}>
          <Flex gap={2} align="center">
            <Input
              defaultValue={
                searchParams.get("input") ? searchParams.get("input") : ""
              }
              variant="outline"
              name="input"
              placeholder="Outline"
            />
            <IconButton
              as="button"
              type="submit"
              aria-label="Search database"
              icon={<AiOutlineSearch />}
            />
          </Flex>
        </form>
      </Flex>
      <TableContainer bg={color} borderRadius="lg">
        <Table>
          <Thead bg={colorz}>
            <Tr>
              <Th p="10px" textAlign="center">
                <Tooltip hasArrow label="Accepted">
                  Ping
                </Tooltip>
              </Th>
              <Th p="10px" textAlign="center">
                <Tooltip hasArrow label="Support">
                  Post
                </Tooltip>
              </Th>
              <Th p="10px" textAlign="center">
                Type
              </Th>

              <Th p="10px" textAlign="center">
                Zipcode
              </Th>
              <Th p="10px" textAlign="center">
                firstname
              </Th>
              <Th p="10px" textAlign="center">
                lastName
              </Th>
              <Th p="10px" textAlign="center">
                phone
              </Th>
              <Th p="10px" textAlign="center">
                email
              </Th>
              <Th p="10px" textAlign="center">
                city
              </Th>
              <Th p="10px" textAlign="center">
                state
              </Th>
              <Th p="10px" textAlign="center">
                address
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {!isLoading &&
              data.data &&
              data.data.map((item, index) => {
                return (
                  <Tr key={index}>
                    <Td textAlign="center" p="10px">
                      {item.ping ? (
                        <Center>
                          <RiSignalTowerFill color="green" />
                        </Center>
                      ) : (
                        <Center>
                          {" "}
                          <HiOutlineStatusOffline color="red" />
                        </Center>
                      )}
                    </Td>
                    <Td textAlign="center" p="10px">
                      {item.post ? (
                        <Center>
                          <AiOutlineCheckCircle color="green" />
                        </Center>
                      ) : (
                        <Center>
                          <AiOutlineCloseCircle color="red" />
                        </Center>
                      )}
                    </Td>
                    <Td textAlign="center" p="10px">
                      {item.type}
                    </Td>
                    <Td textAlign="center" p="10px">
                      {item.zip}
                    </Td>
                    <Td textAlign="center" p="10px">
                      {item.firstname}
                    </Td>
                    <Td textAlign="center" p="10px">
                      {item.lastname}
                    </Td>
                    <Td textAlign="center" p="10px">
                      {item.phone}
                    </Td>
                    <Td textAlign="center" p="10px">
                      {item.email}
                    </Td>
                    <Td textAlign="center" p="10px">
                      {item.city}
                    </Td>
                    <Td textAlign="center" p="10px">
                      {item.state}
                    </Td>
                    <Td textAlign="center" p="10px">
                      {item.address}
                    </Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex p={[4, 4, 4, 4]} justify="space-between">
        {/* <Flex align="center" gap="6">
          {!isLoading && (
            <Select
              value={limit}
              onChange={(e) => setLimit(parseInt(e.target.value))}
            >
              <option value={4}>4</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
            </Select>
          )}
          {data.data && (
            <Text>
              Showing {limit} out 0f {data.count}
            </Text>
          )}
        </Flex> */}
        {data ? (
          <Pagination
            count={data.count}
            limit={limit}
            page={page}
            setPage={setPage}
          />
        ) : (
          <PaginationLoader />
        )}
      </Flex>
    </Box>
  );
};

export default RenovizeLeads;

import axios from "axios";
import Domain from "../Endpoint";

export const addProject = async (data) => {
  const rsp = await axios.post(`${Domain}/admin/addProjects`, data);
  return rsp.data.result;
};

export const addLead = async (data) => {
  const rsp = await axios.post(`${Domain}/web/33miles`, data);
  return rsp.data;
};

export const getProjects = async (page, limit, search) => {
  const rsp = await axios.post(`${Domain}/admin/getProjects`, {
    page,
    state: search,
    limit,
  });

  return rsp.data.result;
};

export const getUserProjects = async (page, limit) => {
  const rsp = await axios.get(`${Domain}/user/getProjct`, {
    params: { page, limit },
  });

  return rsp.data.result;
};
export const getProjectData = async (page, search, limit, id, service) => {
  const rsp = await axios.post(`${Domain}/admin/getProjectsData`, {
    page,
    limit,
    query: {
      pid: id,
      ...(service ? { sid: service } : {}),
      ...(search ? { zipcode: search } : {}),
    },
  });

  return rsp.data.result;
};

export const addService = async (data) => {
  const rsp = await axios.post(`${Domain}/admin/addServices`, data);
  return rsp.data.result;
};

export const getService = async (page, limit, search) => {
  const rsp = await axios.get(`${Domain}/admin/getServices`, {
    params: {
      page,
      state: search,
      limit,
    },
  });
  return rsp.data.result;
};

export const addServiceData = async (data) => {
  const rsp = await axios.post(`${Domain}/admin/addServiceData`, data);
  return rsp.data.result;
};

export const deleteProject = async () => {
  const rsp = await axios.put(`${Domain}/admin/Projects`);
  console.log(rsp);
  return rsp.data.result;
};

export const getVerticles = async (page, limit) => {
  const rsp = await axios.get(`${Domain}/admin/verticle`, {
    page,
    limit,
  });
  console.log(rsp);
  return rsp.data.result;
};

export const getType = async () => {
  const rsp = await axios.put(`${Domain}/admin/verticle`);
  console.log(rsp);
  return rsp.data.result;
};

export const getLogsProfitxl = async (page, limit, search) => {
  const rsp = await axios.post(`${Domain}/admin/profitxl`, {
    page,
    search,
    limit,
  });
  console.log(rsp);
  return rsp.data.result;
};

export const addVerticle = async (data) => {
  const rsp = await axios.post(`${Domain}/admin/ports`, data);
  return rsp.data.result;
};

export const addPort = async (data) => {
  const rsp = await axios.post(`${Domain}/admin/project`, data);
  return rsp.data.result;
};

export const getPorts = async (page, limit, search) => {
  const rsp = await axios.post(`${Domain}/admin/getPorts`, {
    page,
    state: search,
    limit,
  });
  return rsp?.data?.result;
};

export const addMedicare = async (data) => {
  const rsp = await axios.post(`${Domain}/user/medicareLead`, data);
  return rsp?.data;
};

export const addMedicareQoutes = async (data) => {
  const rsp = await axios.post(`${Domain}/user/medicareLead-qoutes`, data);
  return rsp?.data;
};

export const addMedicare2 = async (data) => {
  const rsp = await axios.post(`${Domain}/user/medicareLeadPx`, data);
  return rsp?.data;
};
export const searchZipCode = async (data) => {
  const rsp = await axios.post(`${Domain}/user/searchzip`, data);
  return rsp.data.result;
};

export const getDataLotLeads = async (page, limit, projectId) => {
  const rsp = await axios.post(`${Domain}/admin/getDataLotLeads`, {
    page,
    ...(projectId ? { pId: projectId } : {}),
    limit,
  });
  return rsp?.data?.result;
};

export const getmedicarePx = async (page, limit) => {
  const rsp = await axios.post(`${Domain}/admin/getmedicarePx`, {
    page,
    limit,
  });
  return rsp?.data?.result;
};

export const addWindow = async (data) => {
  const rsp = await axios.post(`${Domain}/user/windowLeads`, data);
  return rsp?.data;
};
export const getWindowsLeads = async (page, limit, id) => {
  const rsp = await axios.post(`${Domain}/admin/getWindowLeads`, {
    page,
    limit,
    ...(id ? { query: { id: id } } : {}),
  });
  return rsp?.data?.result;
};

export const windowPingPost = async (data) => {
  const rsp = await axios.post(`${Domain}/admin/postWindowLead`, data);
  return rsp?.data;
};

export const addKitchen = async (data) => {
  const rsp = await axios.post(`${Domain}/user/kitchenLeads`, data);
  return rsp?.data;
};

export const getKitchenLeads = async (page, limit, id) => {
  const rsp = await axios.post(`${Domain}/admin/getKitchenLeads`, {
    page,
    limit,
    ...(id ? { query: { id: id } } : {}),
  });
  return rsp?.data?.result;
};

export const kitchenPingPost = async (data) => {
  const rsp = await axios.post(`${Domain}/admin/postKitchenLead`, data);
  return rsp?.data;
};

export const addBathroom = async (data) => {
  const rsp = await axios.post(`${Domain}/user/bathroomLeads`, data);
  return rsp?.data;
};
export const getBathroomLeads = async (page, limit, id) => {
  const rsp = await axios.post(`${Domain}/admin/getBathroom`, {
    page,
    limit,
    ...(id ? { query: { id: id } } : {}),
  });
  return rsp?.data?.result;
};

export const bathroomPingPost = async (data) => {
  const rsp = await axios.post(`${Domain}/admin/postBathroom`, data);
  return rsp?.data;
};

export const addSiding = async (data) => {
  const rsp = await axios.post(`${Domain}/user/slidingLeads`, data);
  return rsp?.data;
};
export const getSidingLeads = async (page, limit, id) => {
  const rsp = await axios.post(`${Domain}/admin/getSliding`, {
    page,
    limit,
    ...(id ? { query: { id: id } } : {}),
  });
  return rsp?.data?.result;
};

export const sidingPingPost = async (data) => {
  const rsp = await axios.post(`${Domain}/admin/postSliding`, data);
  return rsp?.data;
};

export const addInqPost = async (data) => {
  const rsp = await axios.post(`${Domain}/user/inq-posting`, data);
  return rsp?.data;
};

export const addRoofing = async (data) => {
  const rsp = await axios.post(`${Domain}/user/roofingLeads`, data);
  return rsp?.data;
};
export const addRoofingOne = async (data) => {
  const rsp = await axios.post(`${Domain}/user/roofingOneleads`, data);
  return rsp?.data;
};

export const getRoofingLeads = async (page, limit, id) => {
  const rsp = await axios.post(`${Domain}/admin/getroofingeads`, {
    page,
    limit,
    ...(id ? { query: { id: id } } : {}),
  });
  return rsp?.data?.result;
};

export const roofingPingPost = async (data) => {
  const rsp = await axios.post(`${Domain}/admin/postRoofingLead`, data);
  return rsp?.data;
};

export const addFlooring = async (data) => {
  const rsp = await axios.post(`${Domain}/user/flooringLeads`, data);
  return rsp?.data;
};

export const getFlooringLeads = async (page, limit, id) => {
  const rsp = await axios.post(`${Domain}/admin/getFlooring`, {
    page,
    limit,
    ...(id ? { query: { id: id } } : {}),
  });
  return rsp?.data?.result;
};

export const flooringPingPost = async (data) => {
  const rsp = await axios.post(`${Domain}/admin/postFloorig`, data);
  return rsp?.data;
};

export const deleteServiceData = async (id) => {
  const rsp = await axios.put(`${Domain}/admin/serviceData${id}`);
  return rsp?.data;
};

export const bulkDeleteServiceData = async (data) => {
  console.log(data);
  const rsp = await axios.put(
    `${Domain}/admin/serviceDataBulk/${data.pId}/${data.sId}`
  );
  return rsp?.data;
};

export const getInqLeads = async (page, limit, id) => {
  const rsp = await axios.post(`${Domain}/admin/getleadsInq`, {
    page,
    limit,
    ...(id ? { query: { id: id } } : {}),
  });
  return rsp?.data?.result;
};

export const addSolar = async (data) => {
  const rsp = await axios.post(`${Domain}/user/solarLeads`, data);
  return rsp?.data;
};
export const getSolarLeads = async (page, limit, id) => {
  const rsp = await axios.post(`${Domain}/admin/getSolar`, {
    page,
    limit,
    ...(id ? { query: { id: id } } : {}),
  });
  return rsp?.data?.result;
};

export const solarPingPost = async (data) => {
  const rsp = await axios.post(`${Domain}/admin/postSolar`, data);
  return rsp?.data;
};

export const addHome = async (data) => {
  const rsp = await axios.post(`${Domain}/user/HomeLeads`, data);
  return rsp?.data;
};
export const getHomeLeads = async (page, limit, id) => {
  const rsp = await axios.post(`${Domain}/admin/getHome`, {
    page,
    limit,
    ...(id ? { query: { id: id } } : {}),
  });
  return rsp?.data?.result;
};
export const getAutoLeads = async (page, limit, id) => {
  const rsp = await axios.post(`${Domain}/admin/getAuto`, {
    page,
    limit,
    ...(id ? { query: { id: id } } : {}),
  });
  return rsp?.data?.result;
};

export const HomePingPost = async (data) => {
  const rsp = await axios.post(`${Domain}/admin/postHome`, data);
  return rsp?.data;
};

export const addHav = async (data) => {
  const rsp = await axios.post(`${Domain}/user/havLeads`, data);
  return rsp?.data;
};
export const getHavLeads = async (page, limit, id) => {
  const rsp = await axios.post(`${Domain}/admin/gethav`, {
    page,
    limit,
    ...(id ? { query: { id: id } } : {}),
  });
  return rsp?.data?.result;
};

export const havPingPost = async (data) => {
  const rsp = await axios.post(`${Domain}/admin/postHav`, data);
  return rsp?.data;
};

export const addGutter = async (data) => {
  const rsp = await axios.post(`${Domain}/user/GutterLeads`, data);
  return rsp?.data;
};
export const getGutterLeads = async (page, limit, id) => {
  const rsp = await axios.post(`${Domain}/admin/getGutter`, {
    page,
    limit,
    ...(id ? { query: { id: id } } : {}),
  });
  return rsp?.data?.result;
};

export const GutterPingPost = async (data) => {
  console.log(data);
  const rsp = await axios.post(`${Domain}/admin/postGutter`, data);
  return rsp?.data;
};

export const getBuyerData = async (page, limit, id) => {
  const rsp = await axios.get(`${Domain}/buyer/lead`);
  return rsp?.data?.result;
};

export const addSettlement = async (data) => {
  const rsp = await axios.post(`${Domain}/user/setelment`, data);
  return rsp?.data;
};

export const getSettlement = async (page, limit, id) => {
  const rsp = await axios.get(`${Domain}/admin/setelment`);
  return rsp?.data?.result;
};
export const setelmentPingPost = async (data) => {
  console.log(data);
  const rsp = await axios.post(`${Domain}/admin/setelmentPost`, data);
  return rsp?.data;
};

export const addAuto = async (data) => {
  const rsp = await axios.post(`${Domain}/user/auto`, data);
  return rsp?.data;
};

export const addHomeInc = async (data) => {
  const rsp = await axios.post(`${Domain}/user/HomeInsurance`, data);
  return rsp?.data;
};

export const getMedicareQoutesLeads = async (page, limit, id) => {
  const rsp = await axios.post(`${Domain}/admin/getMedicareQoutes`, {
    page,
    limit,
    ...(id ? { query: { id: id } } : {}),
  });
  return rsp?.data?.result;
};

export const medicareQoutesPingPost = async (data) => {
  const rsp = await axios.post(`${Domain}/admin/postMedicareQoutes`, data);
  return rsp?.data;
};

export const getDnc = async (number) => {
  const rsp = await axios.get(`${Domain}/user/dnc/${number}`);
  return rsp.data.result.data;
};
export const getRenovieLead = async (page, limit, search) => {
  const rsp = await axios.post(`${Domain}/admin/leads`, {
    page,
    search,
    limit,
  });
  console.log(rsp);
  return rsp.data.result;
};

import { AiFillWindows, AiOutlineSearch } from "react-icons/ai";
import { MdFlourescent, MdRoofing, MdKitchen, MdCleaningServices } from "react-icons/md";
import { MdMailOutline, MdHouseSiding } from "react-icons/md";
import { GiFloorPolisher, GiLeak } from "react-icons/gi";
import {
  FaBath,
  FaBookMedical,
  FaCarAlt,
  FaHandHoldingMedical,
  FaHouseDamage,
} from "react-icons/fa";
import { BsDoorOpenFill } from "react-icons/bs";
import { WiSolarEclipse } from "react-icons/wi";
import { uid } from "uid";

export const Publiclinks = [
  {
    id: uid(),
    name: "Zipcode Search",
    path: "/",
    icon: <AiOutlineSearch size="20" />,
    child_routes: null,
  },
  {
    id: uid(),
    name: "Dnc Scruber",
    path: "/dnc",
    icon: <MdCleaningServices size="20" />,
    child_routes: null,
  },
  {
    id: uid(),
    name: "Roofing",
    path: "/Roofing",
    icon: <MdRoofing size="20" />,
    child_routes: null,
  },
  {
    id: uid(),
    name: "Window",
    path: "/window",
    icon: <AiFillWindows size="20" />,
    child_routes: null,
  },
  {
    id: uid(),
    name: "Kitchen",
    path: "/kitchen",
    icon: <MdKitchen size="20" />,
    child_routes: null,
  },

  {
    id: uid(),
    name: "Flooring",
    path: "/flooring",
    icon: <GiFloorPolisher size="20" />,
    child_routes: null,
  },

  {
    id: uid(),
    name: "Bathroom",
    path: "/bathroom",
    icon: <FaBath size="20" />,
    child_routes: null,
  },

  {
    id: uid(),
    name: "Siding",
    path: "/siding",
    icon: <MdHouseSiding size="20" />,
    child_routes: null,
  },

  {
    id: uid(),
    name: "Ever Live",
    path: "/vert",
    icon: <MdFlourescent size="20" />,
    child_routes: null,
  },

  {
    id: uid(),
    name: "inq-posting",
    path: "/inq-posting",
    icon: <MdMailOutline size="20" />,
    child_routes: null,
  },
  {
    id: uid(),
    name: "solar",
    path: "/solar",
    icon: <WiSolarEclipse size="20" />,
    child_routes: null,
  },

  {
    id: uid(),
    name: "Gutter",
    path: "/gutter",
    icon: <GiLeak size="20" />,
    child_routes: null,
  },

  {
    id: uid(),
    name: "HVAC",
    path: "/hvac",
    icon: <BsDoorOpenFill size="20" />,
    child_routes: null,
  },
  {
    id: uid(),
    name: "Medicare2",
    path: "/medicare2",
    icon: <FaHandHoldingMedical size="20" />,
    child_routes: null,
  },
  {
    id: uid(),
    name: "settlement",
    path: "/settlement",
    icon: <FaHandHoldingMedical size="20" />,
    child_routes: null,
  },
  {
    id: uid(),
    name: "Home warranty",
    path: "/home",
    icon: <FaHandHoldingMedical size="20" />,
    child_routes: null,
  },
  {
    id: uid(),
    name: "Auto Insurance 2",
    path: "/auto2",
    icon: <FaCarAlt size="20" />,
    child_routes: null,
  },

  {
    id: uid(),
    name: "Home Insurance",
    path: "/home-insurance",
    icon: <FaHouseDamage size="20" />,
    child_routes: null,
  },
  {
    id: uid(),
    name: "Medicare Qoutes",
    path: "/medicare-Qoutes",
    icon: <FaBookMedical size="20" />,
    child_routes: null,
  },
  {
    id: uid(),
    name: "Roofing One",
    route: "/roofing-one",
    path: "/roofing-one",
    icon: <MdRoofing />,
    layout: true,
  },
  //
];

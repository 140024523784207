import axios from "axios";

export const fetchYears = async () => {
  const response = await axios("https://orcrm.co/api/autosafty");
  console.log(response);
  return response.data.Results;
};

export const fetchMake = async (payload) => {
  const response = await axios(
    `https://orcrm.co/api/autosafty/${payload.year}`
  );

  console.log(response);
  return { data: response.data.Results, secound: payload.secound };
};
export const fetchModel = async (payload) => {
  const response = await axios(
    `https://orcrm.co/api/autosafty/${payload.year}/${payload.make}`
  );

  console.log(response);
  return { data: response.data.Results, secound: payload.secound };
};

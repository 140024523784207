import { Routes, Route, useNavigate } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import Layout from "./components/layout/Layout";
import _routeServices from "./services/_routeServices";
import Login from "./pages/login/login";
import { QueryClient, QueryClientProvider} from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { useAppStore } from "./store/AppStore";
import useAuthStore from "./store/AuthStore";

function App() {
  const queryClient = new QueryClient();
  const navigate = useNavigate();
  const { setNavlinks } = useAppStore((state) => state);
  const { user } = useAuthStore((state) => state);
  useEffect(() => {
    if (user) setNavlinks(true);
    // if (user) navigate("/profitxl");
  }, [user]);
  return (
    <Box>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <Layout>
          <Routes>
            <Route path="/login" element={<Login />} />

            {_routeServices.map((x) => (
              <Route path={x.route} element={x.component} key={x.id} />
            ))}
          </Routes>
        </Layout>

        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </QueryClientProvider>
    </Box>
  );
}

export default App;
